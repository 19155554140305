import React, { Component } from 'react';
import { Anonymous } from "./Anonymous";
import { Authenticated } from "./Authenticated";

export class Home extends Component {
    static displayName = Home.name;

    constructor() {
        super();

        this.state = {
            isAuthenticated: false,
            isLoading: true,
        };
    }

    componentDidMount() {
        fetch("/api/google/status")
            .then((response) => response.json().then(data => this.setState((state) => ({
                ...state,
                ...data,
                isLoading: false
            }))));
    }

    render() {
        if (this.state.isLoading) {
            return <pre>Loading...</pre>;
        }

        return (
            <div>
                <h1>Welcome</h1>
                { this.state?.isAuthenticated
                ? <Authenticated />
                : <Anonymous />
                }
            </div>
        );
    }
}
