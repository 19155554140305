import React, { Component } from 'react';

export class DriveContents extends Component {
    constructor() {
        super();

        this.state = {
            isLoading: true,
            items: [],
        };
    }

    componentDidMount() {
        this.loadDriveItems();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.driveId === this.props.driveId) {
            return;
        }

        this.setState((state) => ({
            ...state,
            isLoading: true,
        }));

        this.loadDriveItems();
    }

    loadDriveItems() {
        fetch("/api/google/drives/" + this.props.driveId)
            .then(response => response
                .json()
                .then(data => this.setState(
                    (state) => ({
                        ...state,
                        isLoading: false,
                        items: data,
                    })
                ))
            );

    }

    render() {
        if (this.state.isLoading) {
            return <pre>Loading...</pre>;
        }

        return (
            <table cellPadding="10" cellSpacing="10">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Name</th>
                        <th>Created</th>
                        <th>LastEditedBy</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.items.map(item => (
                        <tr key={item.id}>
                            <td>
                                {item.directory ? "DIR" : "FILE" }
                            </td>
                            <td>
                                <a
                                    href={item.editUrl}
                                    target="_blank"
                                >
                                    {item.name }
                                </a>
                            </td>
                            <td>
                                {item.date.toString()}
                            </td>
                            <td>
                                {(item.owner || {}).name}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }
}