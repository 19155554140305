import React, { Component } from 'react';
import { GoogleDrive } from "./GoogleDrive";

export class Authenticated extends Component {
    static displayName = Authenticated.name;

    constructor() {
        super();

        this.state = {
            name: "",
            isLoading: true,
        };
    }

    componentDidMount() {
        fetch("/api/google/profile").then(response => response.json().then(data => this.setState(
            (state) => ({
                ...state,
                isLoading: false,
                name: data.name,
            })
        )));
    }

    render() {
        if (this.state.isLoading) {
            return <pre>Loading...</pre>;
        }

        return (
            <div>
                <h2>Hello,&nbsp;{this.state.name}!</h2>
                <GoogleDrive />
            </div>
        );
    }
}