import React, { Component } from 'react';
import { DriveContents } from "./DriveContents";

export class GoogleDrive extends Component {
    constructor() {
        super();

        this.state = {
            drives: [],
            isLoading: true,
            selectedDriveId: "",
        };

        this.onDriveChange = this.onDriveChange.bind(this);
    }

    componentDidMount() {
        fetch("/api/google/drives")
            .then(response => response.json()
                .then(data => this.setState((state) => ({
                    ...state,
                    drives: data,
                    isLoading: false,
                    selectedDriveId: data.map(drive => drive.id)[0],
                })))
            );
    }

    onDriveChange(event) {
        event.persist();

        this.setState(state => ({
            ...state,
            selectedDriveId: event.target.value,
        }))
    }

    render() {
        if (this.state.isLoading) {
            return <pre>Loading</pre>;
        }

        return (
            <div>
                <div>
                    <select
                        onChange={this.onDriveChange }
                        defaultValue={this.state.selectedDriveId}
                    >
                        {this.state.drives.map(
                            (drive) => (
                                <option
                                    key={drive.id}
                                    value={drive.id}
                                >
                                    {drive.name}
                                </option>
                            )
                        )}
                    </select>
                </div>
                <div>{this.renderDriveContents()}</div>
            </div>
        )
    }

    renderDriveContents() {
        if (!this.state.selectedDriveId) {
            return null;
        }

        return <DriveContents driveId={this.state.selectedDriveId } />;
    }
}