import React, { Component } from 'react';

export class Anonymous extends Component {
    static displayName = Anonymous.name;

    constructor() {
        super();

        this.state = { username: "" };

        this.onAuthenticateClick = this.onAuthenticateClick.bind(this);
        this.onUsernameChange = this.onUsernameChange.bind(this);
    }

    onAuthenticateClick(event) {
        event.preventDefault();

        fetch("/api/google/auth-url?username=" + encodeURIComponent (this.state.username) )
            .then((response) => response.json().then((json) => window.location = json.authUrl));
    }

    onUsernameChange(event) {
        this.setState((state) => ({
            ...state,
            username: event.target.value
        }));
    }

    render() {
        return (
            <div>
                <p>Webb-appen har inte tillgång till ditt Google-konto. Fyll i ditt användarnamn och klicka sedan på knappen nedan för att försöka autentisera dig.</p>
                <input
                    name="username"
                    onChange={this.onUsernameChange}
                    type="text"
                    value={this.state.username}
                />
                <input
                    onClick={this.onAuthenticateClick}
                    type="button"
                    defaultValue={"Autentisera mot Google"}
                />
            </div>
        )
    }
}